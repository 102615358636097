import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import appVariables from '@/config'
import {
  AuthStateProvider,
  useAuthState,
} from '@/components/page-specific/gm/components/states/auth'
import { useSetUserForGuestBasketsMutation } from '@/generated/graphql'
import { clearAllLocalBasketIds, getAllLocalBasketIds } from '../states/local-basket'
import { GlobalStyles } from '@caterdesk/ui--theme'
import Navigation from '@/components/page-specific/gm/components/navigation'
import { ErrorBoundary } from '../error-boundary'
import { AppStateProvider } from '@/components/page-specific/gm/components/states/app'
import { BasketStateProvider } from '@/components/page-specific/gm/components/states/basket'
import Head from 'next/head'
import { useSupport } from '@/components/Providers/SupportProvider'
import Script from 'next/script'

const PopulatedErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const support = useSupport()

  return <ErrorBoundary onChatToUsClick={support.show}>{children}</ErrorBoundary>
}

const Content: React.FC<AppProps & { withNav?: boolean }> = ({ Component, pageProps, withNav }) => {
  const authState = useAuthState()
  const [runSetUserForGuestBasketMutation] = useSetUserForGuestBasketsMutation()

  const userId = authState.type === 'authenticated' && authState.user.id
  useEffect(() => {
    if (!userId) return

    const basketIds = getAllLocalBasketIds()
    if (basketIds.length === 0) return

    void runSetUserForGuestBasketMutation({
      variables: { basketIds },
    })

    clearAllLocalBasketIds()
  }, [userId])

  return (
    <>
      <GlobalStyles />
      {withNav ? <Navigation /> : null}
      <PopulatedErrorBoundary>
        <Component {...pageProps} />
      </PopulatedErrorBoundary>
    </>
  )
}

const withGm = (Component: any, withNav = true) => {
  // eslint-disable-next-line react/display-name
  const WithGm: React.FC<AppProps> = (props) => (
    <PopulatedErrorBoundary>
      <Head>
        <title>Catering | Feedr</title>
        <meta name="title" content="Feedr | Corporate catering that delivers on every occasion" />
        <meta
          name="description"
          content="Corporate catering for all occasions from breakfast buffets and sandwich platters to salad boxes and sushi. Served straight to the office, just how you like it."
        />
        {appVariables.FEEDR_ENVIRONMENT === 'test' && (
          <link rel="manifest" href="/pwa/manifest.json" />
        )}
      </Head>
      <Script src="https://assets.caterdesk-static.com/website-assets/sourcing.min.js" />
      <AuthStateProvider>
        {/* @ts-expect-error unknown */}
        <BasketStateProvider>
          {/* @ts-expect-error unknown */}
          <AppStateProvider>
            {/* @ts-expect-error unknown */}
            <Content Component={Component} pageProps={props} withNav={withNav} />
          </AppStateProvider>
        </BasketStateProvider>
      </AuthStateProvider>
    </PopulatedErrorBoundary>
  )

  return WithGm
}

export default withGm
