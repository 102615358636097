import { KeyboardArrowDown } from '@mui/icons-material'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton } from '@mui/material'
import type { MouseEvent } from 'react'
import { Fragment, useMemo, useState } from 'react'
import { useAuthState } from '../states/auth'
import { useCountry, useLocale } from '@/components/Link'
import { getCountryCodes, getCountryDataFromCountryCodes } from './functions'
import { useAppState } from '../states/app'
import { useGetAllVatRegionsQuery } from '@/generated/graphql'

export function CountryDropdown() {
  const currentLocale = useLocale()
  const currentCountryCode = useCountry()
  const authState = useAuthState()
  const appState = useAppState()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(locale?: string) {
    setAnchorEl(null)

    if (!locale || locale === '') {
      return
    }

    if (locale.toLowerCase() === currentLocale?.toLowerCase()) {
      return
    }

    appState.updateUserState({ location: undefined })

    // We are using the window location rather than the Next router
    // as it causes issues when changing the locale. This way seems
    // to be more reliable and predictable (and works!).
    window.location.pathname = `/${locale}/office-catering/vendors`
  }

  const invoiceAccounts = useMemo(() => {
    return authState.type === 'authenticated' ? (authState.user.account?.invoiceAccounts ?? []) : []
  }, [authState.type])

  const { data: vatRegionsData } = useGetAllVatRegionsQuery()
  const taxRegions = vatRegionsData?.vatRegions ?? []

  const countryCodes = useMemo(
    () => getCountryCodes(invoiceAccounts, taxRegions).sort(),
    [invoiceAccounts, taxRegions],
  )
  const mapped = useMemo(() => getCountryDataFromCountryCodes(countryCodes), [countryCodes])

  if (authState.type !== 'authenticated') {
    return null
  }

  // If there's no invoice accounts, then there's no need to show this
  // dropdown to the users.
  if (invoiceAccounts.length === 0) {
    return null
  }

  const selected = mapped.find((v) => v.label === currentCountryCode)

  return (
    <Fragment>
      <Button
        id="locale-switcher-button"
        type="button"
        aria-haspopup="true"
        aria-controls={open ? 'locale-switcher-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        startIcon={
          selected ? (
            <img
              width={18}
              height={13.5}
              src={selected?.flagUri ?? ''}
              style={{ marginLeft: '4px', marginRight: '2px' }}
              alt={`${selected?.label} flag`}
            />
          ) : null
        }
        endIcon={<KeyboardArrowDown sx={{ ml: -0.5 }} />}
        size="medium"
        sx={(t) => ({ pl: 1, color: t.palette.grey[700], gap: 0 })}
      >
        {selected ? (
          selected.label
        ) : (
          <Skeleton variant="rounded" height={20} width={49} sx={{ mr: '0px' }} />
        )}
      </Button>

      <Menu
        id="locale-switcher-menu"
        aria-labelledby="locale-switcher-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {mapped.map((country) => (
          <MenuItem
            key={country.label}
            onClick={() => handleClose(country.locale)}
            selected={country.label === currentCountryCode}
          >
            <ListItemIcon>
              <img width={18} height={13.5} src={country.flagUri} alt={`${country.label} flag`} />
            </ListItemIcon>

            <ListItemText>{country.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}
