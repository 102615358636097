import { useContext, useDebugValue } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'
import useTenant from '@/hooks/useTenant'

export enum SPLITS {
  USE_GOOGLE = 'GoogleGeocoding',
  USE_NEW_SAVED_ADDRESSED = 'NewGmSavedAddresses',
  REMOVE_ITEM_NOTES = 'removeItemNotes',
  USE_NEW_HEADCOUNT_FILTER = 'GMHeadcountFilter',
  BUDGET_CODE_MANDATORY = 'GmMandatoryBudgetCode',
  EAT_FIRST_BRANDING = 'GmEatFirstBranding',
  ACCOUNT_REFERENCE_FIELDS = 'AccountReferenceFields',
  GM_CONFIRM_DETAILS_ADDRESS_LOOKUP_MUI = 'gmConfirmDetailsAddressLookupMUI',
  EATFIRST_ISSUES_NOTICE = 'EatfirstIssuesNotice',
  SHOW_CONTACT_ASSIGNMENT_CHECKOUT = 'showContactAssignmentGmCheckout',
  TURN_OFF_DEFAULT_INVOICE_ACCOUNT = 'turnOffDefaultInvoiceAccount',
}

// eslint-disable-next-line no-undef
export const useFeatureFlag = (treatmentName: SPLITS, attributes: SplitIO.Attributes = {}) => {
  const { client } = useContext(SplitContext)
  const tenant = useTenant()
  if (!client) return false

  const treatmentValue = client.getTreatment(treatmentName, { ...attributes, tenantId: tenant.id })
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDebugValue(`${treatmentName}: ${treatmentValue}`)

  return treatmentValue === 'on'
}
