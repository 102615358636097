import useTenant from '@/hooks/useTenant'
import Link, { useCountry, useRouter } from '@/components/Link'
import { Button } from '@mui/material'

const RequestAQuoteButton = () => {
  const tenant = useTenant()
  const countryCode = useCountry()
  const router = useRouter()

  if (router.pathname !== '/[domain]/[lang]/office-catering/vendors') return null

  const requestAQuote = tenant.marketing?.links?.find(
    (l) => l.countryCode === countryCode.toLowerCase(),
  )?.requestAQuote

  if (!requestAQuote) return null

  return (
    <Link href={requestAQuote} passHref>
      <Button variant="contained" size="medium">
        Get a quote
      </Button>
    </Link>
  )
}

export default RequestAQuoteButton
