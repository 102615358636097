import type { AuthGmQuery, GetAllVatRegionsQuery } from '@/generated/graphql'
import countries from '@/helpers/countries'

type InvoiceAccount = NonNullable<AuthGmQuery['me']['account']>['invoiceAccounts'][number]

/**
 * Handle getting all the country codes we should display to the user based
 * on their invoice accounts.
 *
 * @param invoiceAccounts the currently signed in users' account invoice accounts.
 *
 * @returns an array of country codes from the invoice accounts.
 */
export function getCountryCodes(
  invoiceAccounts: InvoiceAccount[],
  vatRegions: GetAllVatRegionsQuery['vatRegions'],
): string[] {
  if (invoiceAccounts.length === 0 || vatRegions.length === 0) {
    return []
  }

  return invoiceAccounts.reduce((arr: string[], curr) => {
    const taxRegion = vatRegions.find((region) => region.legacyId === curr.vatRegionId)

    if (taxRegion && !arr.includes(taxRegion.code)) {
      arr.push(taxRegion.code)
    }

    return arr
  }, [])
}

type CountryData = {
  code: string
  label: string
  locale: string
  flagUri: string
}

type CountryDataResult = CountryData[]

/**
 * Handle getting the associated country data from the found country codes.
 *
 * @param countryCodes the codes we want to get the country data for.
 *
 * @returns an array of all the countries found by country code.
 */
export function getCountryDataFromCountryCodes(countryCodes: string[]): CountryDataResult {
  return countryCodes.reduce((arr, code) => {
    const found = countries.find((country) => country.code === code)

    if (found) {
      arr.push({
        code: found.code,
        label: found.code,
        locale: found.locales[0],
        flagUri: found.flagImageUri.rectangle,
      })
    }

    return arr
  }, [] as CountryDataResult)
}
