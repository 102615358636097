import _ from 'lodash'
import { z } from 'zod'

const LOCAL_STORAGE_KEY = 'gm__basket-ids'

const basketIdSchemaV1 = z.string().min(1)
const basketIdSchemaV2 = z.array(
  z.object({
    id: z.string(),
    friendlyId: z.string().nullish(),
    customName: z.string().nullish(),
  }),
)
// map of vendor ID to local basket ID, e.g. { nusa: "basket123" }
const basketIdsSchema = z.record(z.union([basketIdSchemaV1, basketIdSchemaV2]).optional())
type BasketIds = z.infer<typeof basketIdsSchema>

const retrieveBasketIds = (): BasketIds => {
  const rawIds: string = localStorage[LOCAL_STORAGE_KEY] || '{}'
  return basketIdsSchema.parse(JSON.parse(rawIds))
}
const storeBasketIds = (basketIds: BasketIds) => {
  localStorage[LOCAL_STORAGE_KEY] = JSON.stringify(basketIds)
}

export const getLocalBasketDataForVendor = (vendorId: string) => retrieveBasketIds()[vendorId]
export const setLocalBasketDataForVendor = (
  vendorId: string,
  basketData: z.infer<typeof basketIdSchemaV2>,
): void => {
  storeBasketIds({
    ...retrieveBasketIds(),
    [vendorId]: basketData,
  })
}
export const getAllLocalBasketIds = () => {
  const basketIds = retrieveBasketIds()
  const flatMappedData = Object.values(basketIds).flatMap((basketData) =>
    Array.isArray(basketData) ? basketData.map((basket) => basket.id) : basketData,
  )
  return _.compact(flatMappedData)
}

export const clearAllLocalBasketIds = () => storeBasketIds({})
