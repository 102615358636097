import { useCallback, useMemo, useSyncExternalStore } from 'react'

export function useSsrFriendlyLocalStorage<T>(key: string, initialValue?: T | null) {
  const parsedInitialValue =
    typeof initialValue !== 'undefined' && initialValue !== null
      ? JSON.stringify(initialValue)
      : null

  const rawData = useSyncExternalStore(
    (onChange) => {
      const onStorageEvent = (e: Event) => {
        const customEvent = e as CustomEvent
        if (customEvent.detail.key === key) {
          onChange()
        }
      }
      window.addEventListener('storage', onChange)
      window.addEventListener('local-storage-change', onStorageEvent as EventListener)
      return () => {
        window.removeEventListener('storage', onChange)
        window.removeEventListener('local-storage-change', onStorageEvent as EventListener)
      }
    },
    () => {
      const data = localStorage.getItem(key)
      return data || parsedInitialValue
    },
    () => parsedInitialValue,
  )

  const setData = useCallback(
    (value: T | ((prev: T | null) => T)) => {
      const currentRawData = localStorage.getItem(key)
      const currentValue = currentRawData ? (JSON.parse(currentRawData) as T) : null

      const newValue =
        typeof value === 'function' ? (value as (prev: T | null) => T)(currentValue) : value

      localStorage.setItem(key, JSON.stringify(newValue))
      window.dispatchEvent(new CustomEvent('local-storage-change', { detail: { key } }))
    },
    [key],
  )

  const data = useMemo<T | null>(() => {
    if (typeof rawData !== 'string') return null
    return JSON.parse(rawData) as T
  }, [rawData])

  return [data, setData] as const
}
