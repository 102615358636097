import { useApolloClient } from '@apollo/client'
import _ from 'lodash'
import { useLocale, useRouter } from '@/components/Link'
import moment from 'moment'
import { z } from 'zod'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { lookupAddressDetails } from '@/components/page-specific/gm/helpers/addresses'
import { useAppError } from '../../utils/errors'
import { UserBasketSettings } from '../../domain/user-basket-settings'
import { AppErrors } from '../../types/AppErrors'
import { UserBasketSettingsLocationFragment } from '@/generated/graphql'
import { useSsrFriendlyLocalStorage } from '@/hooks/useSsrFriendlyLocalStorage'

type AppState = {
  userState: UserBasketSettings
  updateUserState: (userState: UserBasketSettings) => void
  errors: AppErrors
  setErrors: (error: AppErrors) => void
}

const AppStateContext = createContext<AppState | null>(null)

const defaultUserState = {}

const routerQuerySchema = z.object({
  postcode: z.string().min(3).optional(),
  street: z.string().min(3).optional(),
  date: z.string().min(10).optional(),
  time: z.string().min(5).optional(),
})

const useAppStateImplementation = (): AppState => {
  const [errors, setErrors] = useState<AppErrors>({})
  const router = useRouter()
  const locale = useLocale()
  const apolloClient = useApolloClient()
  const [userState, setUserState] = useSsrFriendlyLocalStorage<UserBasketSettings>(
    'userBasketSettings',
    defaultUserState,
  )

  const updateErrors = (newErrors: AppErrors) => {
    const newErrorsCopy = _.omitBy(newErrors, _.isNull)
    setErrors({ ...newErrorsCopy })
  }

  const updateUserState = useCallback(
    (newUserState: Partial<UserBasketSettings>) => {
      setUserState((prev) => ({ ...defaultUserState, ...prev, ...newUserState }))
    },
    [setUserState],
  )

  const { postcode, street, date, time } = routerQuerySchema.parse(router.legacyQuery) // router.query doesn't have this search params, use legacyQuery for now.

  useEffect(() => {
    if (router.pathname !== '/[domain]/[lang]/office-catering/vendors') return
    const collectDetails = async () => {
      let newLocation: UserBasketSettingsLocationFragment | null = null
      if (postcode && street) {
        const foundAddress = await lookupAddressDetails(
          { street, postcode, city: '' },
          apolloClient,
          true,
          locale,
        )
        if (foundAddress) newLocation = foundAddress as UserBasketSettingsLocationFragment
      }
      let minDateTime: moment.Moment | null = null
      if (date) {
        minDateTime = moment(date)
        if (time) {
          const [hours, minutes] = time.split(':')
          minDateTime.add(hours, 'hours').add(minutes, 'minutes')
        }
      }
      updateUserState({
        initalised: true,
        ...((newLocation || userState?.location) && {
          location: newLocation || userState?.location,
        }),
        ...(minDateTime && { date: minDateTime.valueOf() }),
        ...(minDateTime &&
          time && {
            time: `${minDateTime.format('HH:mm')} - ${minDateTime
              .add(30, 'minutes')
              .format('HH:mm')}`,
          }),
      })
    }
    void collectDetails()
  }, [])

  return {
    userState: { ...defaultUserState, ...userState },
    updateUserState,
    errors,
    setErrors: updateErrors,
  }
}

// @ts-expect-error unknown
export const AppStateProvider: React.FC = ({ children }) => {
  const state = useAppStateImplementation()

  return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
}

/**
 * @deprecated We realised that using global context causes performance and code readability issues.
 * Hence we are incrementally migrating bits away from this state.
 * Please try to access values from alternative states (e.g. useUserBasketSettingsState)
 * or if adding new logic then aim to add it elsewhere.
 */
export const useAppState = (): AppState => {
  const { throwAppError } = useAppError()
  const state = useContext(AppStateContext)

  if (!state)
    return throwAppError({ type: 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER', name: 'AppState' })

  return state
}
