import type { AuthGmQuery } from '@/generated/graphql'
import { UserRole } from '@/generated/graphql'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material'
import { loggedInLinks, loggedInManagerLinks } from './constants'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import {
  Dashboard,
  KeyboardArrowDown,
  LocalDining,
  Logout,
  ManageAccounts,
} from '@mui/icons-material'
import { useLocaleLink, useRouter } from '@/components/Link'
import { match } from 'ts-pattern'

type SignedInDropdownProps = {
  user: AuthGmQuery['me']
}

export function SignedInDropdown(props: SignedInDropdownProps) {
  const { user } = props

  const generateLocaleLink = useLocaleLink()
  const router = useRouter()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  async function handleClose(url?: string) {
    setAnchorEl(null)

    if (!url || url === '') {
      return
    }

    await router.push(url)
  }

  return (
    <Stack>
      <Button
        id="authenticated-dropdown-button"
        type="button"
        aria-haspopup="true"
        aria-controls={open ? 'authenticated-dropdown-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        endIcon={<KeyboardArrowDown sx={{ ml: -0.5 }} />}
        size="medium"
        sx={(t) => ({ pl: 1, color: t.palette.grey[700], gap: 0 })}
      >
        {user.firstName} {user.lastName}
      </Button>

      <Menu
        id="authenticated-dropdown-menu"
        aria-labelledby="authenticated-dropdown-button"
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {(user.role === UserRole.Manager ? loggedInManagerLinks : loggedInLinks).map((link) => {
          return (
            <MenuItem key={link.url} onClick={() => handleClose(generateLocaleLink(link.url))}>
              <ListItemIcon sx={{ color: link.color }}>
                {link.icon
                  ? match(link.icon)
                      .with('Logout', () => <Logout fontSize="small" />)
                      .with('LocalDining', () => <LocalDining fontSize="small" />)
                      .with('Dashboard', () => <Dashboard fontSize="small" />)
                      .with('ManageAccounts', () => <ManageAccounts fontSize="small" />)
                      .exhaustive()
                  : null}
              </ListItemIcon>

              <ListItemText
                primaryTypographyProps={{ color: link.color }}
                sx={{ color: link.color }}
              >
                {link.name}
              </ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </Stack>
  )
}
