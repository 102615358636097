import type React from 'react'
import { useMedia } from 'react-use'
import { match } from 'ts-pattern'
import { resolveLogoVariant } from '@caterdesk/ui--feedr-logo'
import Dropdown, { DropdownLink } from '@caterdesk/ui--dropdown'
import { main, mobileMediaQuery } from '@caterdesk/ui--theme'
import { useAuthState } from '../states/auth'
import { NavListItem } from './index.styles'
import Mobile from './mobile'
import { loggedInLinks, loggedInManagerLinks } from './constants'
import RequestAQuoteButton from './requestAQuoteButton'
import { UserRole } from '@/generated/graphql'
import { Button, Container, Stack, Tooltip } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import Link, { useLocaleLink, useRouter } from '@/components/Link'
import useTenant from '@/hooks/useTenant'
import { CountryDropdown } from './countryDropdown'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { useEffect, useRef } from 'react'
import { SignedInDropdown } from './signedInDropdown'

type Props = {
  previewData?: { itemQuantity: number; grandTotal: string } | null
  newItemWasJustAdded?: boolean
  onHeightChange?: (height: number) => void
  onClickBasketPreview?: () => void
}

const Navigation: React.FC<Props> = ({
  previewData,
  newItemWasJustAdded,
  onClickBasketPreview,
  onHeightChange,
}) => {
  const router = useRouter()
  const tenant = useTenant()
  const generateLocaleLink = useLocaleLink()
  const authState = useAuthState()
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const ref = useRef<HTMLDivElement>(null)

  const showCountryDropdown = useFeatureFlag(SPLITS.MARKETPLACE_COUNTRY_DROPDOWN)
  const showNewSignedInDropdown = useFeatureFlag(SPLITS.NEW_MARKETPLACE_SIGNED_IN_DROPDOWN)

  const showBasketPreview = previewData
  const itemCount = previewData?.itemQuantity ?? 0
  const grandTotal = previewData?.grandTotal ?? ''

  const handleMobileClick = () => {
    if (!onClickBasketPreview) return
    onClickBasketPreview()
  }

  const handleDesktopClick = () => {
    const orderDetailsTop = document.getElementById('input-bar')

    if (orderDetailsTop) {
      const offset = 8
      const orderDetailsPosition = orderDetailsTop.getBoundingClientRect().top + window.scrollY
      const offsetPosition = orderDetailsPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const handleBasketIconClicked = () => {
    if (isMobileScreen) {
      handleMobileClick()
    } else {
      handleDesktopClick()
    }
  }

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current && onHeightChange) {
        onHeightChange(ref.current.offsetHeight)
      }
    }

    const observer = new ResizeObserver(updateHeight)

    if (ref.current) observer.observe(ref.current)

    return () => observer.disconnect()
  }, [])

  return (
    <Stack
      ref={ref}
      sx={{
        display: 'block',
        position: 'sticky',
        top: 0,
        zIndex: 30,
        background: '#fff',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.05)',
        padding: '12px 16px',
        md: { padding: '16px 32px' },
      }}
    >
      <Container component="nav">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={{ xs: '6px', md: '16px' }}>
            <NavListItem>
              <Link href="/">
                <img
                  src={resolveLogoVariant({
                    setToRainbowDuringPride: true,
                    logos: tenant.marketing.logos,
                  })}
                  alt={tenant.name}
                  height={isMobileScreen ? 35 : 50}
                />
              </Link>
            </NavListItem>

            <NavListItem>CATERING</NavListItem>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={{ xs: '6px', md: '10px' }}>
            <RequestAQuoteButton />

            {showBasketPreview && (
              <Stack>
                <Tooltip
                  title={
                    <>
                      <div>Added to basket!</div>
                      <div>Subtotal: {grandTotal}</div>
                    </>
                  }
                  placement="bottom"
                  open={(isMobileScreen && newItemWasJustAdded) || false}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color={itemCount > 0 ? 'primary' : 'secondary'}
                    size="medium"
                    onClick={handleBasketIconClicked}
                    startIcon={<ShoppingCart sx={{ ml: 1, px: '1px' }} />}
                    sx={{ pl: 1 }}
                  >
                    {isMobileScreen
                      ? itemCount
                      : `${itemCount} item${itemCount === 1 ? '' : 's'}, ${grandTotal}`}
                  </Button>
                </Tooltip>
              </Stack>
            )}

            {showCountryDropdown ? <CountryDropdown /> : null}

            {isMobileScreen ? (
              <Mobile />
            ) : (
              match(authState)
                .with({ type: 'authenticated' }, ({ user }) =>
                  showNewSignedInDropdown ? (
                    <SignedInDropdown user={user} />
                  ) : (
                    <Stack>
                      <Dropdown title={`${user.firstName} ${user.lastName}`}>
                        {(user.role === UserRole.Manager
                          ? loggedInManagerLinks
                          : loggedInLinks
                        ).map((link) => (
                          <DropdownLink
                            key={link.name}
                            name={link.name}
                            color={link.color || main.colors.text}
                            href={generateLocaleLink(link.url)}
                          />
                        ))}
                      </Dropdown>
                    </Stack>
                  ),
                )
                .with({ type: 'loading' }, { type: 'unauthenticated' }, () => (
                  <Stack>
                    <Link href={`/authentication/login?destination=${router.asPath}`} passHref>
                      <Button variant="contained" size="medium">
                        Sign in
                      </Button>
                    </Link>
                  </Stack>
                ))
                .exhaustive()
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  )
}

export default Navigation
