import { main } from '@caterdesk/ui--theme'
import config from '@/config'

export const loggedInLinks = [
  {
    name: 'My dashboard',
    url: '/dashboard',
    icon: 'Dashboard' as const,
  },
  {
    name: 'Try Canteen?',
    url: config.URI_CANTEEN,
    icon: 'LocalDining' as const,
  },
  {
    name: 'Log out',
    url: '/authentication/logout',
    color: main.colors.error,
    icon: 'Logout' as const,
  },
]

export const loggedInManagerLinks = [
  {
    name: 'My dashboard',
    url: '/dashboard',
    icon: 'Dashboard' as const,
  },
  {
    name: 'Admin dashboard',
    url: `${config.URI_CANTEEN}/ca`,
    icon: 'ManageAccounts' as const,
  },
  {
    name: 'Try Canteen?',
    url: config.URI_CANTEEN,
    icon: 'LocalDining' as const,
  },
  {
    name: 'Log out',
    url: '/authentication/logout',
    color: main.colors.error,
    icon: 'Logout' as const,
  },
]
